import Home from "../pages/home";
import Link from "../pages/link";
import Register from "../pages/register";
import Print from "../pages/print";
import Support from "../pages/support";

const routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/link",
    element: <Link />,
  },
  {
    path: "/register",
    element: <Register />,
    
  },
  {
    path:"/support",
    element : <Support/>
  },
  {
    // Dinamik route ile productId ve userId parametrelerini yakalıyoruz
    path: "/print-request/:productId",
    element: <Print />,
  },
  {
    path: "*",
    element: <Home />,
  },
];

export default routes;
