import React, { useRef, useState } from "react";
import { API_URL, API_PORT } from "../../constants/appConfig";
import { useLanguage } from "../../components/languageprovider";
import DeafultUserImage from "../../assets/images/defaultUserImage.png";
import { CardContent, Card, Button, CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import language from "../../constants/language";
import RegisterStepOne from "./registerstepone";
import RegisterStepTwo from "./registersteptwo";
import RegisterStepThree from "./registerstepthree";
import RegisterStepFour from "./registerstepfour";
import RegisterStepFive from "./registerstepfive";
import { motion } from "framer-motion";
import RegisterSuccess from "./registersuccess";

const Index = () => {
  const { currentLanguage, changeLanguage } = useLanguage();
  const texts = language[currentLanguage];
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1); // Adımları yönetmek için
  const [imageData, setImageData] = useState(null);
  const [userPackage, setUserPackage] = useState({ packageId: "" });
  const [kvkkAccepted, setKvkkAccepted] = useState(false);
  const [userBankAccount, setUserBankAccount] = useState({
    iban: "",
    bankName: "",
    bankAccountUserName: "",
    bankAccountUserSurname: "",
    paypalNumber: "",
  });
  const [isSelectedPackageMonth, setIsSelectedPackageMonth] = useState();
  const [user, setUser] = useState({
    userName: "",
    userSurname: "",
    userUsername: "",
    userPassword: "",
    userRole: "user",
    userTrial: false,
    userEmail: "",
    userPhone: "",
    userFirmName: "",
    userTaxNumber: "",
    userSelectedBg: "",
    userCountry: "de",
    userPostalCode: "",
  });
  const [newImage, setNewImage] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [userSaving, setUserSaving] = useState(false);
  const [ibanError, setIbanError] = useState("");

  const fileInputRef = useRef(null);

  const countries = [
    {
      id: "de",
      name: "German",
      flags: {
        svg: "https://flagicons.lipis.dev/flags/4x3/de.svg",
      },
    },
    {
      id: "tr",
      name: "Turkish",
      flags: {
        svg: "https://flagicons.lipis.dev/flags/4x3/tr.svg",
      },
    },
    {
      id: "en",
      name: "English",
      flags: {
        svg: "https://flagicons.lipis.dev/flags/4x3/gb.svg",
      },
    },
  ];

  const handleImageSelection = (imageId) => {
    setSelectedImages((prevSelectedImages) => {
      if (prevSelectedImages.includes(imageId)) {
        return prevSelectedImages.filter((id) => id !== imageId);
      } else {
        return [...prevSelectedImages, imageId];
      }
    });
  };

  const handleSelectPriceType = (value) => {
    setIsSelectedPackageMonth(value);
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const allowedExtensions = ["jpeg", "jpg", "png"];
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      toast.error(texts.fileExtensionError);
      return;
    }

    setNewImage(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImageData(reader.result);
    };
  };

  const uploadImage = async (userImage) => {
    try {
      const formData = new FormData();
      formData.append("file", userImage);
      const imageType = "userimages";
      const response = await fetch(
        `${API_URL}:${API_PORT}/image/uploadImage?imageType=${imageType}`,
        {
          method: "POST",
          body: formData,
        }
      );
      return await response.json();
    } catch (error) {
      throw error;
    }
  };

  const saveUser = async (sendedImage) => {
    try {
      const userDto = {
        userDTO: {
          userName: user.userName,
          userSurname: user.userSurname,
          userUsername: user.userUsername,
          userPassword: user.userPassword,
          userRole: user.userRole,
          userTrial: true,
          userEmail: user.userEmail,
          userPhone: user.userPhone,
          userFirmName: user.userFirmName,
          userTaxNumber: user.userTaxNumber,
          userCountry: user.userCountry,
          userPostalCode: user.userPostalCode,
          userSelectedBg: selectedImages.join(","),
          image: {
            imageId: sendedImage?.imageId,
          },
        },
        bankAccountDTO: userBankAccount,
        apackage: {
          packageId: userPackage.packageId,
        },
        isSelectedPackageMonth: isSelectedPackageMonth,
      };

      const response = await fetch(
        `${API_URL}:${API_PORT}/user/CollectiveSaveUser`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userDto),
        }
      );

      return await response.json();
    } catch (error) {
      toast.error(texts.saveError);
    }
  };

  const saveUserImageAndUserRelation = async (imageId, userId) => {
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/ImageUserRelation/saveImageUserRelation`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            image: {
              imageId: imageId,
            },
            user: { userId: userId },
            imageType: "userimages",
            status: true,
          }),
        }
      );
      return await response.json();
    } catch (error) {
      toast.error(texts.saveError);
    }
  };

  const saveUserAndImage = async () => {
    setUserSaving(true);
    try {
      const defaultUserImage = await fetch(DeafultUserImage);
      const defaultUserImageBlob = await defaultUserImage.blob();
      const defaultUserImageFile = new File(
        [defaultUserImageBlob],
        "defaultUserImage.png"
      );
      const userImage = newImage || defaultUserImageFile;
      let imageResponse = await uploadImage(userImage);
      if (!imageResponse?.imageId) {
        toast.error(texts.saveError);
        return;
      }

      const userResponse = await saveUser(imageResponse);

      if (userResponse?.userDTO?.userId) {
        if (imageResponse?.imageId) {
          const relationResponse = await saveUserImageAndUserRelation(
            imageResponse.imageId,
            userResponse?.userDTO?.userId
          );
          if (!relationResponse) {
            toast.error(texts.saveError);
            return;
          }
        }
        setStep(6);
        toast.success(texts.saveSuccess);
      } else {
        toast.error(texts.saveError);
      }
    } catch (error) {
      toast.error(texts.saveError);
    }
    setUserSaving(false);
  };

  const handleSave = async () => {
    await saveUserAndImage();
  };

  // RegisterStepTwo.js
  const validateStepTwo = () => {
    const {
      userName,
      userSurname,
      userEmail,
      userUsername,
      userPassword,
      userPhone,
      userFirmName,
      userTaxNumber,
    } = user;

    if (
      !userName ||
      !userSurname ||
      !userEmail ||
      !userUsername ||
      !userPassword ||
      !userPhone ||
      !userFirmName ||
      !userTaxNumber
    ) {
      toast.error(texts.fieldsRequired); // Hata mesajı göster
      return false; // Geçersiz
    }

    // E-posta formatı kontrolü (opsiyonel)
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(userEmail)) {
      toast.error(texts.invalidEmail); // Geçersiz e-posta mesajı
      return false; // Geçersiz
    }

    // Telefon numarası kontrolü (opsiyonel)
    if (userPhone.length < 10) {
      toast.error(texts.invalidPhoneNumber); // Geçersiz telefon numarası mesajı
      return false; // Geçersiz
    }

    return true; // Geçerli
  };
  // RegisterStepThree.js
  const validateStepFour = () => {
    const {
      iban,
      bankName,
      bankAccountUserName,
      bankAccountUserSurname,
      paypalNumber,
    } = userBankAccount;

    // PayPal numarası varsa, sadece kullanıcı adı ve soyadı zorunlu
    if (ibanError) {
      toast.error(texts.invalidIban); // Geçersiz IBAN mesajı
      return false; // Geçersiz IBAN olduğunda işlemi durdur
    }
    if (paypalNumber) {
      if (!bankAccountUserName || !bankAccountUserSurname) {
        toast.error(texts.bankAccountOwnerRequired);
        return false; // Geçersiz
      }
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(paypalNumber)) {
        toast.error(texts.invalidPaypalMail); // Geçersiz e-posta mesajı
        return false; // Geçersiz
      }
    } else if (iban) {
      // IBAN girilmişse, banka adı ve kullanıcı adı/soyadı zorunlu
      if (!bankAccountUserName || !bankAccountUserSurname) {
        toast.error(texts.bankAccountOwnerRequired);
        return false; // Geçersiz
      }
      if (!bankName) {
        toast.error(texts.bankNameRequired);
        return false; // Geçersiz
      }
    } else {
      // IBAN veya PayPal numarasından en az birinin doldurulmuş olması gerekiyor
      toast.error(texts.bankAccountRequired);
      return false; // Geçersiz
    }

    return true; // Geçerli
  };
  // RegisterStepFour.js
  const validateStepThree = () => {
    const { userPostalCode, userCountry } = user;

    // Posta kodu kontrolü
    if (userPostalCode.length !== 5) {
      toast.error(texts.invalidZipCode); // Hata mesajı göster
      return false; // Geçersiz
    }

    // Ülke seçimi kontrolü
    if (!userCountry) {
      toast.error(texts.countryRequired); // Hata mesajı göster
      return false; // Geçersiz
    }

    return true; // Geçerli
  };
  // RegisterStepFive.js
  const validateStepFive = () => {
    // Paket seçimi kontrolü
    if (!userPackage) {
      toast.error(texts.packageRequired); // Hata mesajı göster
      return false; // Geçersiz
    }

    // Ödeme planı seçimi kontrolü
    if (!isSelectedPackageMonth) {
      toast.error(texts.paymentPlanRequired); // Hata mesajı göster
      return false; // Geçersiz
    }

    // Arka plan seçimi kontrolü (en az 1, en fazla 3)
    if (selectedImages.length < 1 || selectedImages.length > 3) {
      toast.error(texts.backgroundSelectionLimit); // Hata mesajı göster
      return false; // Geçersiz
    }

    // KVKK onayı kontrolü
    if (!kvkkAccepted) {
      toast.error(texts.kvkkRequired); // Hata mesajı göster
      return false; // Geçersiz
    }

    return true; // Tüm kontroller geçerli
  };

  const handleNextStep = () => {
    let isValid = true;
    switch (step) {
      case 2:
        isValid = validateStepTwo();
        break;
      case 3:
        isValid = validateStepThree();
        break;
      case 4:
        isValid = validateStepFour();
        break;
      case 5:
        isValid = validateStepFive();
        break;
      default:
        break;
    }

    if (isValid) {
      if (step < 5) setStep(step + 1);
      if (step === 5) {
        handleSave();
      }
    }
  };

  const handlePreviousStep = () => {
    if (step > 1) setStep(step - 1);
  };

  return (
    <>
      {loading ? (
        <div className="w-full min-w-max flex items-center justify-center">
          <CircularProgress size={80} />
        </div>
      ) : (
        <div className="min-h-screen grid grid-rows-[1fr_auto] p-4">
          <Card className="shadow-2xl p-4 rounded-lg">
            <motion.div
              className="flex flex-wrap gap-4 items-center justify-center"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 1 }}
            >
              {countries.map((country, index) => (
                <button
                  key={index}
                  onClick={() => {
                    changeLanguage(country.id);
                  }}
                >
                  <img
                    className="w-16 rounded-lg cursor-pointer hover:scale-110 transition ease 1s"
                    src={country.flags.svg}
                    alt={country.name}
                  />
                </button>
              ))}
            </motion.div>
            <CardContent>
              <h2 className="text-center text-xl font-semibold">Register</h2>
              <div className="flex flex-col gap-4">
                {step === 1 && (
                  <RegisterStepOne
                    imageData={imageData}
                    fileInputRef={fileInputRef}
                    triggerFileInput={triggerFileInput}
                    handleFileUpload={handleFileUpload}
                    texts={texts}
                  />
                )}
                {step === 2 && (
                  <RegisterStepTwo
                    user={user}
                    setUser={setUser}
                    texts={texts}
                  />
                )}
                {step === 3 && (
                  <RegisterStepThree user={user} setUser={setUser} />
                )}
                {step === 4 && (
                  <RegisterStepFour
                    user={user}
                    userBankAccount={userBankAccount}
                    setUserBankAccount={setUserBankAccount}
                    texts={texts}
                    ibanError={ibanError}
                    setIbanError={setIbanError}
                  />
                )}
                {step === 5 && (
                  <RegisterStepFive
                    isSelectedPackageMonth={isSelectedPackageMonth}
                    handleSelectPriceType={handleSelectPriceType}
                    selectedImages={selectedImages}
                    handleImageSelection={handleImageSelection}
                    setUserPackage={setUserPackage}
                    userPackage={userPackage}
                    kvkkAccepted={kvkkAccepted}
                    setKvkkAccepted={setKvkkAccepted}
                  />
                )}
                {step === 6 && <RegisterSuccess />}
                {step === 6 ? null : (
                  <div className="flex justify-between mt-4">
                    <Button
                      variant="outlined"
                      disabled={userSaving || step === 1}
                      onClick={() => handlePreviousStep()}
                      sx={{
                        fontSize: { xs: "12px", sm: "inherit" }, // Mobilde küçük font, diğerlerinde normal
                        minWidth: { xs: "80px", sm: "auto" }, // Mobilde minimum genişlik
                        padding: { xs: "6px 12px", sm: "8px 16px" }, // Mobilde daha az padding
                      }}
                    >
                      {texts.previousStep}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => handleNextStep()}
                      sx={{
                        fontSize: { xs: "12px", sm: "inherit" }, // Mobilde küçük font, diğerlerinde normal
                        minWidth: { xs: "80px", sm: "auto" }, // Mobilde minimum genişlik
                        padding: { xs: "6px 12px", sm: "8px 16px" }, // Mobilde daha az padding
                      }}
                    >
                      {userSaving ? (
                        <CircularProgress color="inherit" size={20} /> // Yükleme simgesinin boyutu
                      ) : step === 5 ? (
                        texts.saveUser
                      ) : (
                        texts.nextStep
                      )}
                    </Button>
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
        </div>
      )}
    </>
  );
};

export default Index;
