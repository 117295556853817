import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  Typography,
  Select,
  MenuItem,
  CircularProgress,
  Checkbox,
} from "@mui/material";
import { API_URL, API_PORT } from "../../constants/appConfig";
import { useLanguage } from "../../components/languageprovider";
import language from "../../constants/language";
import { toast } from "react-toastify";

const getPackageName = (pkg, currentLanguage) => {
  const packageName = {
    tr: pkg.packageNameTr,
    de: pkg.packageNameDe,
    fr: pkg.packageNameFr,
    it: pkg.packageNameIt,
    nl: pkg.packageNameNl,
    en: pkg.packageNameEn,
  };

  return packageName[currentLanguage] || packageName.en;
};

const RegisterStepFive = ({
  userPackage,
  setUserPackage,
  isSelectedPackageMonth,
  selectedImages,
  handleSelectPriceType,
  handleImageSelection,
  kvkkAccepted,
  setKvkkAccepted,
}) => {
  const { currentLanguage } = useLanguage();
  const texts = language[currentLanguage];
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAllPackage = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/package/getAllPackageWithBg?page=0&size=9999999`
      );
      const data = await response.json();
      setPackages(data?.content || []);
      setUserPackage(data?.content[0]);
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllPackage();
  }, []);

  const handleSelectPackageChange = (event) => {
    const selectedPackage = packages.find(
      (pkg) => pkg.packageId === event.target.value
    );
    setUserPackage(selectedPackage);
  };

  return (
    <>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress sx={{ color: "primary.main" }} />
        </Box>
      ) : (
        <Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
          {/* Paket Seçim Kutusu */}
          <Typography variant="subtitle1">{texts.selectPackage}</Typography>
          <Select
            className="w-full h-10 rounded-lg"
            value={userPackage?.packageId || ""}
            onChange={handleSelectPackageChange}
            displayEmpty
          >
            <MenuItem value="" disabled>
              {texts.selectPackage} {/* Açıklayıcı cümle */}
            </MenuItem>
            {packages.map((pkg) => (
              <MenuItem key={pkg.packageId} value={pkg.packageId}>
                {getPackageName(pkg, currentLanguage)}
              </MenuItem>
            ))}
          </Select>
          {userPackage ? (
            <Box
              sx={{
                border: "1px solid #ddd", // Çerçeve rengi
                borderRadius: "8px", // Kenar yuvarlama
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Gölgelendirme
                p: 3, // İçerik boşluğu
                mt: 2,
              }}
            >
              <Typography variant="subtitle1">
                {texts.packageDescription}
              </Typography>

              <Typography variant="body2">
                {userPackage.packageDescription}
              </Typography>
            </Box>
          ) : null}
          {/* Fiyat seçenekleri */}
          <Box
            sx={{
              border: "1px solid #ddd", // Çerçeve rengi
              borderRadius: "8px", // Kenar yuvarlama
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Gölgelendirme
              p: 3, // İçerik boşluğu
              mt: 2,
            }}
          >
            {userPackage ? (
              <>
                <FormControl component="fieldset">
                  <Typography variant="subtitle1" sx={{ mb: 2 }}>
                    {texts.priceOptions}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      gap: 2,
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={isSelectedPackageMonth === "3"}
                          onChange={() => handleSelectPriceType("3")}
                          sx={{ "&.Mui-checked": { color: "#007bff" } }} // Aktif rengin ayarlanması
                        />
                      }
                      label={`${texts.package3Monthly} - ${userPackage.package3Montly} € ${texts.perMonth}`}
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          checked={isSelectedPackageMonth === "6"}
                          onChange={() => handleSelectPriceType("6")}
                          sx={{ "&.Mui-checked": { color: "#007bff" } }} // Aktif rengin ayarlanması
                        />
                      }
                      label={`${texts.package6Monthly} - ${userPackage.package6Montly} € ${texts.perMonth}`}
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          checked={isSelectedPackageMonth === "12"}
                          onChange={() => handleSelectPriceType("12")}
                          sx={{ "&.Mui-checked": { color: "#007bff" } }} // Aktif rengin ayarlanması
                        />
                      }
                      label={`${texts.packageYearly} -   ${userPackage.package12Montly} € ${texts.perMonth}`}
                    />
                  </Box>
                </FormControl>
              </>
            ) : (
              <Typography variant="body1" color="error">
                {texts.noImagesAvailable} {/* Hata mesajı */}
              </Typography>
            )}
          </Box>

          <Box
            sx={{
              border: "1px solid #ddd", // Çerçeve rengi
              borderRadius: "8px", // Kenar yuvarlama
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Gölgelendirme
              p: 3, // İçerik boşluğu
              mt: 2,
            }}
          >
            {/* Görsel Seçim Bölümü */}
            {userPackage &&
            userPackage.packageImages &&
            userPackage.packageImages.length > 0 ? (
              <>
                <Typography variant="subtitle1">
                  {texts.selectBackground}
                </Typography>{" "}
                {/* Arka plan seçimi açıklaması */}
                <div className="image-selection flex flex-wrap gap-4 mt-4">
                  {userPackage.packageImages.map((item) => (
                    <div
                      key={item.imageId}
                      onClick={() => handleImageSelection(item.imageId)}
                      style={{
                        position: "relative",
                        cursor: "pointer",
                        border: "2px solid",
                        borderColor: selectedImages.includes(item.imageId)
                          ? "blue"
                          : "gray",
                        borderRadius: "8px",
                        overflow: "hidden",
                        width: "100px",
                        display: "inline-block",
                      }}
                    >
                      <img
                        src={`${API_URL}:${API_PORT}/${item.imageName}`}
                        alt={item.imageName}
                        style={{
                          display: "block",
                          width: "100px",
                          transition: "opacity 0.3s ease",
                          opacity: selectedImages.includes(item.imageId)
                            ? 0.6
                            : 1,
                          objectFit: "cover",
                        }}
                      />
                      {selectedImages.includes(item.imageId) && (
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            color: "white",
                            fontSize: "24px",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            padding: "5px 10px",
                            borderRadius: "5px",
                          }}
                        >
                          ✓
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <Typography variant="body1" color="error">
                {texts.noImagesAvailable} {/* Hata mesajı */}
              </Typography>
            )}
          </Box>
          {/* KVKK ve Kullanım Koşulları için Checkbox */}
          <FormControlLabel
            control={
              <Checkbox
                checked={kvkkAccepted}
                onChange={(e) => setKvkkAccepted(e.target.checked)}
              />
            }
            label={
              <Typography variant="body2" sx={{ color: "gray" }}>
                {texts.kvkkConsentMessage}{" "}
                <a
                  href="/kvkk.html"
                  target="_blank"
                  style={{ color: "gray", textDecoration: "underline" }}
                >
                  {texts.kvkkLinkText}
                </a>
              </Typography>
            }
          />
        </Box>
      )}
    </>
  );
};

export default RegisterStepFive;
