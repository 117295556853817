// RegisterStepTwo.js
import React from "react";
import { Box, TextField, Typography } from "@mui/material";

const RegisterStepTwo = ({ user, setUser, texts }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Typography variant="h6" gutterBottom>
        {texts.personalInfo} {/* Genel başlık, dil seçimine göre değişir */}
      </Typography>
      <Typography variant="body2" gutterBottom>
        {texts.infoDescription} {/* Formun üstünde açıklama */}
      </Typography>

      <TextField
        size="small"
        label={texts.name}
        onChange={(e) => setUser({ ...user, userName: e.target.value })}
        value={user.userName || ""}
        fullWidth
      />
      <Typography variant="body2" color="textSecondary">
        {texts.nameDescription} {/* İsim açıklaması */}
      </Typography>

      <TextField
        size="small"
        label={texts.surname}
        onChange={(e) => setUser({ ...user, userSurname: e.target.value })}
        value={user.userSurname || ""}
        fullWidth
      />
      <Typography variant="body2" color="textSecondary">
        {texts.surnameDescription} {/* Soyisim açıklaması */}
      </Typography>

      <TextField
        size="small"
        label={texts.userName}
        onChange={(e) => setUser({ ...user, userUsername: e.target.value })}
        value={user.userUsername || ""}
        fullWidth
      />
      <Typography variant="body2" color="textSecondary">
        {texts.usernameDescription} {/* Kullanıcı adı açıklaması */}
      </Typography>

      <TextField
        size="small"
        label={texts.taxNumber}
        onChange={(e) => setUser({ ...user, userTaxNumber: e.target.value })}
        value={user.userTaxNumber || ""}
        fullWidth
        type="number"
      />
      <Typography variant="body2" color="textSecondary">
        {texts.taxNumberDescription} {/* Vergi numarası açıklaması */}
      </Typography>

      <TextField
        size="small"
        label={texts.phoneNumber}
        onChange={(e) => setUser({ ...user, userPhone: e.target.value })}
        value={user.userPhone || ""}
        fullWidth
        type="number"
      />
      <Typography variant="body2" color="textSecondary">
        {texts.phoneDescription} {/* Telefon numarası açıklaması */}
      </Typography>

      <TextField
        size="small"
        label={texts.email}
        onChange={(e) => setUser({ ...user, userEmail: e.target.value })}
        value={user.userEmail || ""}
        fullWidth
      />
      <Typography variant="body2" color="textSecondary">
        {texts.emailDescription} {/* E-posta açıklaması */}
      </Typography>

      <TextField
        size="small"
        label={texts.password}
        onChange={(e) => setUser({ ...user, userPassword: e.target.value })}
        value={user.userPassword || ""}
        fullWidth
        type="password"
      />
      <Typography variant="body2" color="textSecondary">
        {texts.passwordDescription} {/* Şifre açıklaması */}
      </Typography>

      <TextField
        size="small"
        label={texts.companyName}
        onChange={(e) => setUser({ ...user, userFirmName: e.target.value })}
        value={user.userFirmName || ""}
        fullWidth
      />
      <Typography variant="body2" color="textSecondary">
        {texts.companyDescription} {/* Şirket adı açıklaması */}
      </Typography>
    </Box>
  );
};

export default RegisterStepTwo;